import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Dashboard from './Dashboard';

const Home = () => {
  
  const { oktaAuth, authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  
  // Similar to componentDidMount and componentDidUpdate: 
  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
      oktaAuth.signInWithRedirect({ originalUri: '/' });
    }
  });

  if (!authState) return null;

  return (
    <div>
      <Dashboard />
    </div>
  );
};
export default Home;
