const oktaAuthConfig = {
    // Note: If your app is configured to use the Implicit flow
    // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
    // you will need to add `pkce: false`
    issuer: process.env.REACT_APP_OKTA_URL+'/oauth2/default',
    clientId: process.env.REACT_APP_OKTA_TOKEN,
    //redirectUri: window.location.origin + '/login/callback',
    //redirectUri: 'https://okta-react-app.herokuapp.com/login/callback',
    redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URL,
  };
  
  const oktaSignInConfig = {
    baseUrl: process.env.REACT_APP_OKTA_URL,
    clientId: process.env.REACT_APP_OKTA_TOKEN,
    //redirectUri: window.location.origin + '/login/callback',
    //redirectUri: 'https://okta-react-app.herokuapp.com/login/callback',
    redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URL,
    authParams: {
      // If your app is configured to use the Implicit flow
      // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
      // you will need to uncomment the below line
      // pkce: false
    }
    // Additional documentation on config options can be found at https://github.com/okta/okta-signin-widget#basic-config-options
  };

  const snapSignInConfig = {
    baseUrl: process.env.REACT_APP_SNAPLOGIC_URL,
    clientToken: process.env.REACT_APP_SNAPLOGIC_TOKEN,
    sfdcURL: process.env.REACT_APP_SFDC_URL,
    confluenceURL: process.env.REACT_APP_CONFLUENCE_URL,
    xApiKey:  process.env.REACT_APP_X_API_KEY,
    };
  
  export { oktaAuthConfig, oktaSignInConfig, snapSignInConfig };