/** 
 * cleanDate function returns abbr. month and day from given date - 7/1 9:30 am
 * @param oldDate
 */

function cleanDate(oldDate) {
    if (oldDate === "") return "N/A";
    const splitDate = oldDate.split("-");
    const months = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const monthIndex = parseInt(splitDate[1], 10) - 1;
    return (months[monthIndex] + " " + splitDate[2]);
};

/** 
 * cleanProd function returns abbr. string corresponding to given prod type (should redo) - 7/1 9:30 am
 * @param oldProd
 */

function cleanProd(oldProd) {
    if (oldProd === "Production") {
        return "PROD";
    } 
    
    return "NPROD";
};

/** 
 * getHeading function returns the appropriate heading given wallboard type
 * @param wallboardType
 */

function getHeading(wallboardType) {
    const headingDict = {
        // "Regular": "PRM Escalated Customers, Go-Lives and Critical Weekend Activities:",
        "Top Section (yellow)": "PRM Escalated Customers, Go-Lives and Critical Weekend Activities:",
        "Second Section (blue)": "PRM High Visibility Customers and Longer Running Activities, including Customer Events where customer exceeds thresholds:",
    };

    if (wallboardType in headingDict) {
        return headingDict[wallboardType];
    }

    return undefined;
};

/** 
 * getColor sets appropriate background color of dashboard
 * @param wallboardType
 */

function getColor(wallboardType) {
    const colorDict = {
        // "Regular": "#ffda61",
        "Top Section (yellow)": "#ffda61",
        "Second Section (blue)": "#99e0ff",
    };

    if (wallboardType in colorDict) {
        return colorDict[wallboardType];
    }

    return "#F4F5F7";
};

/** 
 * getStatus gets the appropriate image dependent on the tenant's activity's setting
 * @param tenantStatus
 */

function getStatus(tenantStatus) {
    let status = "aui-lozenge-error";
    if (tenantStatus === "Green") {
        status = "aui-lozenge-success";
    } else if (tenantStatus === "Yellow") {
        status = "aui-lozenge-moved";
    }

    return "status-macro aui-lozenge conf-macro output-inline conf-macro output-inline " + status;
};

/**
 * cleanTags functions removes HTML tags from given input string
 * @param str
 */

function cleanTags(str) {
    if ((str === null) || (str === '')) {
        return null;
    }
        
    str = str.toString();
          
    // Regular expression to identify HTML tags in 
    // the input string. Replacing the identified 
    // HTML tag with a null string.
    return str.replace( /(<([^>]+)>)/ig, '');
};

/**
 * groupBy vanilla js function
 * @param data
 * @param key
 */

 var groupBy = function(data, key) { // `data` is an array of objects, `key` is the key (or property accessor) to group by
    // reduce runs this anonymous function on each element of `data` (the `item` parameter,
    // returning the `storage` parameter at the end
    return data.reduce(function(storage, item) {
    // get the first instance of the key by which we're grouping
    var group = item[key];
    
    // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
    storage[group] = storage[group] || [];
    
    // add this item to its group within `storage`
    storage[group].push(item);
    
    // return the updated storage to the reduce function, which will then loop through the next
    return storage; 
    }, {}); // {} is the initial value of the storage
};

/**
 * comparator for color sorting statuses by red, yellow, and green
 */

var sortOrder = {red: 0, yellow: 1, green: 2};

export { cleanDate, cleanProd, getHeading, getColor, getStatus, cleanTags, groupBy, sortOrder };