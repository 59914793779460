import React, { useState, useEffect } from 'react';
import './css/App.css';
import Wallboard from './components/wallboard.js'
import { useOktaAuth } from '@okta/okta-react';

function Dashboard() {
  const { oktaAuth, authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      }).catch((err) => {
        console.error(err);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const logout = async () => await oktaAuth.signOut();
  const button = <button onClick={logout}>Logout</button>;

  return (
    <div className='App'>
      <h1 className="with-breadcrumbs">
        Production Readiness Managers Wallboard
      </h1>
      <p className="auto-cursor-target-app">
        <a href="https://confluence.workday.com/display/PSS/PRM+RAG+Status+Criteria">PRM RAG Status Criteria</a>
      </p>
      <p>
        {userInfo && (
            <p>Welcome back, {userInfo.name}!</p>
        )}
        {/* {button} */}
      </p>
     
      {/* <Wallboard wallboardType="Regular" /> */}
      <Wallboard wallboardType="Top Section (yellow)" />
      <Wallboard wallboardType="Second Section (blue)" />
    </div>
  );
}
export default Dashboard;