import React, { Fragment } from 'react';
import '../css/prm.css';
import CustomerData from '../data/data.json';
import { cleanDate, cleanProd, getHeading, getColor, getStatus, groupBy, sortOrder} from '../utilities/utilities.js';
import { snapSignInConfig } from '../config';

class Wallboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: true,
            customers: [],
            sortedCustomers: {},
            toggleStatus: {},
            wallboardType: props.wallboardType,
            wallboardHeading: getHeading(props.wallboardType),
            backgroundColor: getColor(props.wallboardType),
            CustomerData: {},
        }
        this.handleToggle = this.handleToggle.bind(this);
        this.postCallBack = this.postCallBack.bind(this);
    }

    //constant URLs
    //workdaySFDCURL = "https://workday.my.salesforce.com/"; //Prod URL
    workdaySFDCURL = snapSignInConfig.sfdcURL; //UAT URL
    confluenceURL = snapSignInConfig.confluenceURL;
    isSnaplogicAPIData = true;

    handleToggle(tenantId, type) {
        let statusCopy = {...this.state.toggleStatus};

        if (statusCopy[tenantId]) {
            statusCopy[tenantId][type] = !statusCopy[tenantId][type];
        }

        this.setState({
            toggleStatus: statusCopy,
        });
    };
    
    //callback method after data is fetched
    postCallBack() {
        let customers = [];
        let sortedCustomers = {};
        let toggleStatus = {};
        if(this.state.CustomerData.group){
            this.state.CustomerData.group.forEach((customer) => {
                customers.push(customer);
            });

            // Sorting and grouping customers alphabetically & by company/status - 7/13 10:00 am

            customers = groupBy(customers, "Wallboard_Type__c");
            //console.log('this.state.wallboardType ', this.state.wallboardType);
            //console.log('snapSignInConfig ',snapSignInConfig);
            customers = customers[this.state.wallboardType];
            for (const customer of customers) {
                toggleStatus[`${customer.Id}`] = {
                    cases: false,
                    concerns: false,
                    products: false,
                    volumes: false,
                    actions: false,
                    summary: false,
                };
            };

            customers.sort(function(a, b) {
                const convertedDate1 = Date.parse(a.Start_Date__c);
                const convertedDate2 = Date.parse(b.Start_Date__c);
                if (convertedDate1 < convertedDate2) { return -1; }
                if (convertedDate1 > convertedDate2) { return 1; }
                return 0;
            });

            // console.log(customers);

            customers.sort(function(a,b) {
                let nameA = a.Account__c.toUpperCase();
                let nameB = b.Account__c.toUpperCase();
                if (nameA < nameB) { return -1; }
                if (nameA > nameB) { return 1; }
                return 0;
            });

            customers = groupBy(customers, "Account__c");

            for (let [key, value] of Object.entries(customers)) {
                value.sort(function (c1, c2) {
                    return sortOrder[c1.Customer_RAG_Status__c] - sortOrder[c2.Customer_RAG_Status__c];
                });
                let activities =  groupBy(value, "Customer_RAG_Status__c");
                customers[key]["statusCount"] = Object.keys(activities).length;
                sortedCustomers[key] = activities;
            }

            // console.log(sortedCustomers);

            this.setState({
                customers: customers,
                sortedCustomers: sortedCustomers,
                toggleStatus: toggleStatus,
            });
        }
    };

    componentDidMount() {
        // Fetch code (needs AWS instance to bypass CORS error)
        if (this.isSnaplogicAPIData) {
            fetch(snapSignInConfig.baseUrl, {
            method: 'POST',
            headers: {
                'Authorization': "Bearer "+snapSignInConfig.clientToken,
                'Content-Type': 'application/json',
                'x-api-key': snapSignInConfig.xApiKey,
            }
            })
            .then(response => response.json())
            .then(result => {
                //console.log('result sp ', JSON.stringify(result));
            this.setState({CustomerData: result}, () => {
                this.postCallBack();
            });
            });
        } else {
                //For local JSON data
            let customers = [];
            let sortedCustomers = {};
            let toggleStatus = {};

            CustomerData.group.forEach((customer) => {
                customers.push(customer);
            });

            // Sorting and grouping customers alphabetically & by company/status - 7/13 10:00 am

            customers = groupBy(customers, "Wallboard_Type__c");
            customers = customers[this.state.wallboardType];

            for (const customer of customers) {
                toggleStatus[`${customer.Id}`] = {
                    cases: false,
                    concerns: false,
                    products: false,
                    volumes: false,
                    actions: false,
                    summary: false,
                };
            };

            customers.sort(function(a, b) {
                const convertedDate1 = Date.parse(a.Start_Date__c);
                const convertedDate2 = Date.parse(b.Start_Date__c);
                if (convertedDate1 < convertedDate2) { return -1; }
                if (convertedDate1 > convertedDate2) { return 1; }
                return 0;
            });

            // console.log(customers);

            customers.sort(function(a,b) {
                let nameA = a.Account__c.toUpperCase();
                let nameB = b.Account__c.toUpperCase();
                if (nameA < nameB) { return -1; }
                if (nameA > nameB) { return 1; }
                return 0;
            });

            customers = groupBy(customers, "Account__c");

            for (let [key, value] of Object.entries(customers)) {
                value.sort(function (c1, c2) {
                    return sortOrder[c1.Customer_RAG_Status__c] - sortOrder[c2.Customer_RAG_Status__c];
                });
                let activities =  groupBy(value, "Customer_RAG_Status__c");
                customers[key]["statusCount"] = Object.keys(activities).length;
                sortedCustomers[key] = activities;
            }

            // console.log(sortedCustomers);

            this.setState({
                customers: customers,
                sortedCustomers: sortedCustomers,
                toggleStatus: toggleStatus,
            });
        }
        
        
    }

    render() {
        return (
            <div id="regular" className="table-wrap">
                <table className="relative-table wrapped confluenceTable" style={{width: "100%"}}>
                    <colgroup>
                        <col style={{width: "10.55%"}} />
                        <col style={{width: "5%"}} />
                        <col style={{width: "84.5%"}} />
                    </colgroup>
                    <tbody>
                        <tr key="header">
                            <th className="highlight-red confluenceTh" colSpan="3" style={{backgroundColor: this.state.backgroundColor}}>
                                <div className="content-wrapper">
                                    <p style={{textAlign: "left"}}>{this.state.wallboardHeading}</p>
                                    <div className="table-wrap conf-macro output-inline appfire-table-plus-parms" data-hasbody="true" data-macro-name="table-plus">
                                        <table id="TBL1597900684735" className="wrapped confluenceTable" width="100%" style={{marginBottom: "10px"}}>
                                            <colgroup>
                                                <col style={{width: "10%"}}/>
                                                <col style={{width: "5%"}}/>
                                                <col style={{width: "10%"}}/>
                                                <col style={{width: "40%"}}/>
                                                <col style={{width: "18%"}}/>
                                                <col style={{width: "17%"}}/>
                                            </colgroup>
                                            <tbody>
                                                <tr key="titles"> 
                                                    <th className="highlight-red confluenceTh nowrap" colSpan="1" data-highlight-colour="red" style={{minWidth: "66px", textAlign: "left", backgroundColor: this.state.backgroundColor}}>Customer</th>
                                                    <th className="highlight-red confluenceTh nowrap" colSpan="1" data-highlight-colour="red" style={{minWidth: "58px", textAlign: "left", backgroundColor: this.state.backgroundColor}}>Status</th>
                                                    <th className="highlight-red confluenceTh nowrap" colSpan="1" data-highlight-colour="red" style={{minWidth: "118px", textAlign: "left", backgroundColor: this.state.backgroundColor}}>Start &amp; End Dates</th>
                                                    <th className="highlight-red confluenceTh nowrap" colSpan="1" data-highlight-colour="red" style={{minWidth: "350px", textAlign: "left", backgroundColor: this.state.backgroundColor}}>Activity &amp; Important Information</th>
                                                    <th className="highlight-red confluenceTh nowrap" colSpan="1" data-highlight-colour="red" style={{minWidth: "188px", textAlign: "left", backgroundColor: this.state.backgroundColor}}>Tenant, Products &amp; Volumes</th>
                                                    <th className="highlight-red confluenceTh nowrap" colSpan="1" data-highlight-colour="red" style={{minWidth: "130px", textAlign: "left", backgroundColor: this.state.backgroundColor}}>Statuses</th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </th>
                        </tr>

                        {/* Displaying grouped/sorted tenants, NOT COMPLETED - 7/13 10:00 am */}

                        {Object.keys(this.state.customers).map(tenant => (
                            <Fragment key={tenant}>
                                <tr key={tenant} style={{border: "1px solid #c1c7d0"}}>
                                    <th className="confluenceTh" style={{backgroundColor: this.state.backgroundColor}} rowSpan={(this.state.customers[tenant]["statusCount"] > 1) ? (this.state.customers[tenant]["statusCount"] + 1) : (1)}>{tenant} {"\n"}
                                    <br></br><a href={this.workdaySFDCURL+`${this.state.customers[tenant][0].PRM_Engagement__c}`} target="_blank" className="external-link" rel="nofollow">Navigate to engagement</a></th>
                                    {(this.state.customers[tenant]["statusCount"] === 1) ? (
                                    <Fragment key={tenant}>
                                        <th className="confluenceTh" colSpan="1" data-highlight-colour="red" style={{minWidth: "58px", justifyContent: "center"}}>
                                            <div className="content-wrapper">
                                                <span className={getStatus(Object.keys(this.state.sortedCustomers[tenant])[0])} style={{marginTop: "11px", justifyContent: "center", display: "flex", alignItems: "center"}} data-hasbody="true" data-macro-name="multiexcerpt">{Object.keys(this.state.sortedCustomers[tenant])[0]}</span>
                                                <p className="auto-cursor-target"><span className="conf-macro output-inline"><strong></strong></span></p>
                                            </div>
                                        </th>
                                        <td className="confluenceTd">
                                            <div className="content-wrapper">
                                                <p><span className="conf-macro output-inline" data-hasbody="false" data-macro-name="multiexcerpt-include"></span></p>
                                                <p></p>
                                                <div className="table-wrap conf-macro output-inline appfire-table-plus-parms" data-hasbody="true" data-macro-name="table-plus">
                                                    <table id="TBL1597900684751" className="wrapped confluenceTable" width="100%" resolved="" style={{marginBottom: "10px"}}>
                                                        <colgroup>
                                                            <col style={{width: "11.3%"}}/>
                                                            <col style={{}}/>
                                                            <col style={{width: "21.4%"}}/>
                                                            <col style={{width: "20.1%"}}/>
                                                        </colgroup>
                                                        <tbody>
                                                            {this.state.sortedCustomers[tenant][Object.keys(this.state.sortedCustomers[tenant])[0]].map(activity => (
                                                                <tr className="confluenceTd" style={{textAlign: "center"}} key={activity.Id}>
                                                                    <td className="confluenceTd" style={{textAlign: "center"}}>
                                                                        <div className="content-wrapper nowrap">
                                                                            <p><span style={{color: "rgb(0,0,128)"}}><strong>{cleanDate(activity.Start_Date__c)} - {cleanDate(activity.End_Date__c)}</strong></span></p>
                                                                        </div>
                                                                    </td>
                                                                    <td className="confluenceTd" style={{textAlign: "left"}}>
                                                                        <div className="content-wrapper">
                                                                        <p><span style={{color: "rgb(0,0,128"}}><strong>{activity.Nexus_Wallboard_Name__c}</strong></span></p>
                                                                            <p className="cloakToggle" onClick={() => this.handleToggle(activity.Id, "cases")} style={{cursor: "pointer"}}>
                                                                                <span className="cloakToggle conf-macro output-inlne" data-macro-name="toggle-cloak">
                                                                                    <span className={this.state.toggleStatus[activity.Id]["cases"] ? "cloakDefaultClose" : "cloakDefaultOpen"} style={{width: "11px"}}></span>
                                                                                </span>
                                                                                <u>Cases, JIRA's and Slack Channels</u>
                                                                            </p>
                                                                            {this.state.toggleStatus[activity.Id]["cases"] &&
                                                                                <span className="cloak  conf-macro output-block" style={{}} data-hasbody="true" data-macro-name="cloak">
                                                                                    <div className="table-wrap conf-macro output-inline appfire-table-plus-parms" data-hasbody="true" data-macro-name="table-plus" style={{}}>
                                                                                        <table id="TBL1597900684749" className="wrapped relative-table confluenceTable" width="100%" style={{width: "99.5381%"}} resolved="">
                                                                                            <colgroup>
                                                                                                <col style={{width: "10%"}}/>
                                                                                                <col style={{width: "30%"}}/>
                                                                                                <col style={{width: "20%"}}/>
                                                                                            </colgroup>
                                                                                            <tbody>
                                                                                                <tr key="headers">
                                                                                                    <td colSpan="1" className="confluenceTd" style={{}}><strong>Cases(s)<br/></strong></td>
                                                                                                    <td colSpan="1" className="confluenceTd" style={{}}><strong>JIRA(s)</strong></td>
                                                                                                    <td colSpan="1" className="confluenceTd" style={{}}><strong>Slack Channel(s)</strong></td>
                                                                                                </tr>
                                                                                                <tr key={activity.Id}>
                                                                                                    <td colSpan="1" className="confluenceTd">
                                                                                                        {!activity.case ||
                                                                                                        activity.case.map(singleCase => (
                                                                                                            <p><a href={this.workdaySFDCURL+`${singleCase.CaseId}`} target="_blank" className="external-link" rel="nofollow">{singleCase.CaseNumber}</a></p>
                                                                                                        ))}
                                                                                                    </td>

                                                                                                    <td colSpan="1" className="confluenceTd">
                                                                                                        {!activity.Jira ||
                                                                                                        activity.Jira.map(singleJira => (    
                                                                                                            <div dangerouslySetInnerHTML={{__html: `${singleJira.Jira_Link__c}`}}></div>
                                                                                                        ))}
                                                                                                    </td>

                                                                                                    <td colSpan="1" className="confluenceTd nowrap">
                                                                                                        <div dangerouslySetInnerHTML={{__html: `${activity.Slack_Channels__c || ""}`}}></div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </span>
                                                                            }
                                                                            <p className="cloakToggle" onClick={() => this.handleToggle(activity.Id, "concerns")} style={{cursor: "pointer"}}>
                                                                                <span className="cloakToggle conf-macro output-inline" data-macro-name="toggle-cloak">
                                                                                    <span className={this.state.toggleStatus[activity.Id]["concerns"] ? "cloakDefaultClose" : "cloakDefaultOpen"} style={{width: "11px"}}></span>
                                                                                </span>
                                                                                <u>Unique Concerns</u>
                                                                            </p>
                                                                            {this.state.toggleStatus[activity.Id]["concerns"] && 
                                                                                <span className="cloak conf-macro output-block" style={{}} data-hasbody="true" data-macro-name="cloak">
                                                                                    <div dangerouslySetInnerHTML={{__html: `${activity.Unique_concerns__c || "NP"}`}}></div>
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td className="confluenceTd" style={{textAlign: "left"}}>
                                                                        <div className="content-wrapper">
                                                                            <p>
                                                                                <a href={this.confluenceURL+`${activity.Tenant_Links__c}&run_Tenant+Name=run`} target="_blank">
                                                                                    <span style={{color: "rgb(0,0,128)"}}>
                                                                                        <strong>{activity.Tenant_Data_Center__c}-{cleanProd(activity.Tenant_Type__c)} - </strong>
                                                                                    </span>
                                                                                    Tenant Details
                                                                                </a>
                                                                            </p>
                                                                            <p className="cloakToggle" onClick={() => this.handleToggle(activity.Id, "products")} style={{cursor: "pointer"}}>
                                                                                <span className="cloakToggle conf-macro output-inlne" data-macro-name="toggle-cloak">
                                                                                    <span className={this.state.toggleStatus[activity.Id]["products"] ? "cloakDefaultClose" : "cloakDefaultOpen"}></span>
                                                                                </span>
                                                                                <u>Implemented Products</u>
                                                                            </p>
                                                                            {this.state.toggleStatus[activity.Id]["products"] && 
                                                                                <span className="cloak conf-macro output-block" style={{}} data-hasbody="true" data-macro-name="cloak">
                                                                                    <div dangerouslySetInnerHTML={{__html: `${activity.Implemented_Products__c || "NP"}`}}></div>
                                                                                </span>
                                                                            }
                                                                            <p className="cloakToggle" onClick={() => this.handleToggle(activity.Id, "volumes")} style={{cursor: "pointer"}}>
                                                                                <span className="cloakToggle conf-macro output-inline" data-macro-name="toggle-cloak">
                                                                                    <span className={this.state.toggleStatus[activity.Id]["volumes"] ? "cloakDefaultClose" : "cloakDefaultOpen"}></span>
                                                                                </span>
                                                                                <u>Key Volumes</u>
                                                                            </p>
                                                                            {this.state.toggleStatus[activity.Id]["volumes"] && 
                                                                                <span className="cloak conf-macro output-block" style={{}} data-hasbody="true" data-macro-name="cloak">
                                                                                    <div dangerouslySetInnerHTML={{__html: `${activity.Key_Volumes__c || "NP"}`}}></div>
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td className="confluenceTd" style={{textAlign: "left"}}>
                                                                        <div className="content-wrapper">
                                                                            <p>
                                                                                <span>
                                                                                    <span>
                                                                                        <span style={{color: "rgb(0,0, 128)"}}>
                                                                                            <strong>PRM - </strong>
                                                                                        </span>
                                                                                        {activity.Assigned_PRM_Text__c}
                                                                                    </span>
                                                                                </span>
                                                                            </p>
                                                                            <p>
                                                                                <span>
                                                                                {activity.Secondary_PRM__c !== null && activity.Secondary_PRM__c !== "" && 
                                                                                    (<span>
                                                                                    <span id="secId" style={{color: "rgb(0,0, 128)"}}>
                                                                                    <strong>Secondary PRM - </strong>
                                                                                    </span>
                                                                                    {activity.Secondary_PRM__c}
                                                                                    </span>)
                                                                                }
                                                                                </span>
                                                                            </p>
                                                                            <p className="auto-cursor-target cloakToggle" onClick={() => this.handleToggle(activity.Id, "actions")}>
                                                                                <span className="cloakToggle conf-macro output-inlne" data-macro-name="toggle-cloak">
                                                                                    <span className={this.state.toggleStatus[activity.Id]["actions"] ? "cloakDefaultClose" : "cloakDefaultOpen"}></span>
                                                                                </span>
                                                                                <u>PRM Actions</u>
                                                                            </p>
                                                                            {this.state.toggleStatus[activity.Id]["actions"] && 
                                                                                <span className="cloak conf-macro output-block" style={{}} data-hasbody="true" data-macro-name="cloak">
                                                                                    <div dangerouslySetInnerHTML={{__html: `${activity.PRM_Actions__c || "NP"}`}}></div>
                                                                                </span>
                                                                            }
                                                                            <p className="auto-cursor-target cloakToggle" onClick={() => this.handleToggle(activity.Id, "summary")}>
                                                                                <span className="cloakToggle conf-macro output-inline" data-macro-name="toggle-cloak">
                                                                                    <span className={this.state.toggleStatus[activity.Id]["summary"] ? "cloakDefaultClose" : "cloakDefaultOpen"}></span>
                                                                                </span>
                                                                                <u>Status Summary</u>
                                                                            </p>
                                                                            {this.state.toggleStatus[activity.Id]["summary"] && 
                                                                                <span className="cloak conf-macro output-block" style={{}} data-hasbody="true" data-macro-name="cloak">
                                                                                    <div dangerouslySetInnerHTML={{__html: `${activity.Activity_Summary__c || "NP"}`}}></div>
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </td>
                                    </Fragment>
                                    ) : (
                                        false
                                    )}
                                </tr>
                                {(this.state.customers[tenant]["statusCount"] > 1) ? (
                                    <Fragment key={`${"inner" + tenant}`}>
                                        {Object.keys(this.state.sortedCustomers[tenant]).map(status => (
                                        <tr key={status} style={{borderBottom: "1px solid #c1c7d0", borderRight: "1px solid #c1c7d0"}}>
                                            <th className="nestedConfluenceTh" data-highlight-color="red" style={{minWidth: "58px", justifyContent: "center"}}>
                                                <div className="content-wrapper">
                                                    <span className={getStatus(status)} style={{marginRight: "3px", justifyContent: "center", display: "flex", alignItems: "center", width: "85%"}} data-hasbody="true" data-macro-name="multiexcerpt">{status}</span>
                                                    <p className="auto-cursor-target">
                                                        <span className="conf-macro output-inline"><strong></strong></span>
                                                    </p>
                                                </div>
                                            </th>
                                            <td className="confluenceTd">
                                                <div className="content-wrapper">
                                                    <p><span className="conf-macro output-inline" data-hasbody="false" data-macro-name="multiexcerpt-include"></span></p>
                                                    <p></p>
                                                    <div className="table-wrap conf-macro output-inline appfire-table-plus-parms" data-hasbody="true" data-macro-name="table-plus">
                                                        <table id="TBL1597900684751" className="wrapped confluenceTable" width="100%" resolved="" style={{marginBottom: "10px"}}>
                                                            <colgroup>
                                                                <col style={{width: "11.3%"}}/>
                                                                <col style={{}}/>
                                                                <col style={{width: "21.4%"}}/>
                                                                <col style={{width: "20.1%"}}/>
                                                            </colgroup>
                                                            <tbody>
                                                                {this.state.sortedCustomers[tenant][status].map(activity => (
                                                                    <tr className="confluenceTd" style={{textAlign: "center"}} key={activity.Id}>
                                                                        <td className="confluenceTd" style={{textAlign: "center"}}>
                                                                            <div className="content-wrapper nowrap">
                                                                                <p><span style={{color: "rgb(0,0,128)"}}><strong>{cleanDate(activity.Start_Date__c)} - {cleanDate(activity.End_Date__c)}</strong></span></p>
                                                                            </div>
                                                                        </td>
                                                                        <td className="confluenceTd" style={{textAlign: "left"}}>
                                                                            <div className="content-wrapper">
                                                                            <p><span style={{color: "rgb(0,0,128"}}><strong>{activity.Nexus_Wallboard_Name__c}</strong></span></p>
                                                                                <p className="cloakToggle" onClick={() => this.handleToggle(activity.Id, "cases")} style={{cursor: "pointer"}}>
                                                                                    <span className="cloakToggle conf-macro output-inlne" data-macro-name="toggle-cloak">
                                                                                        <span className={this.state.toggleStatus[activity.Id]["cases"] ? "cloakDefaultClose" : "cloakDefaultOpen"} style={{width: "11px"}}></span>
                                                                                    </span>
                                                                                    <u>Cases, JIRA's and Slack Channels</u>
                                                                                </p>
                                                                                {this.state.toggleStatus[activity.Id]["cases"] &&
                                                                                    <span className="cloak  conf-macro output-block" style={{}} data-hasbody="true" data-macro-name="cloak">
                                                                                        <div className="table-wrap conf-macro output-inline appfire-table-plus-parms" data-hasbody="true" data-macro-name="table-plus" style={{}}>
                                                                                            <table id="TBL1597900684749" className="wrapped relative-table confluenceTable" width="100%" style={{width: "99.5381%"}} resolved="">
                                                                                                <colgroup>
                                                                                                    <col style={{width: "10%"}}/>
                                                                                                    <col style={{width: "30%"}}/>
                                                                                                    <col style={{width: "20%"}}/>
                                                                                                </colgroup>
                                                                                                <tbody>
                                                                                                    <tr key="headers">
                                                                                                        <td colSpan="1" className="confluenceTd" style={{}}><strong>Cases(s)<br/></strong></td>
                                                                                                        <td colSpan="1" className="confluenceTd" style={{}}><strong>JIRA(s)</strong></td>
                                                                                                        <td colSpan="1" className="confluenceTd" style={{}}><strong>Slack Channel(s)</strong></td>
                                                                                                    </tr>
                                                                                                    <tr key={activity.Id}>
                                                                                                        <td colSpan="1" className="confluenceTd">
                                                                                                            {!activity.case ||
                                                                                                            activity.case.map(singleCase => (
                                                                                                                <p><a href="https://google.com" className="external-link" rel="nofollow">{singleCase.CaseNumber}</a></p>
                                                                                                            ))}
                                                                                                        </td>

                                                                                                        <td colSpan="1" className="confluenceTd">
                                                                                                            {!activity.Jira ||
                                                                                                            activity.Jira.map(singleJira => (
                                                                                                                <p>
                                                                                                                    <div dangerouslySetInnerHTML={{__html: `${singleJira.Jira_Link__c}`}}></div>
                                                                                                                </p>
                                                                                                            ))}
                                                                                                        </td>

                                                                                                        <td colSpan="1" className="confluenceTd nowrap">
                                                                                                            <div dangerouslySetInnerHTML={{__html: `${activity.Slack_Channels__c || ""}`}}></div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </span>
                                                                                }
                                                                                <p className="cloakToggle" onClick={() => this.handleToggle(activity.Id, "concerns")} style={{cursor: "pointer"}}>
                                                                                    <span className="cloakToggle conf-macro output-inline" data-macro-name="toggle-cloak">
                                                                                        <span className={this.state.toggleStatus[activity.Id]["concerns"] ? "cloakDefaultClose" : "cloakDefaultOpen"} style={{width: "11px"}}></span>
                                                                                    </span>
                                                                                    <u>Unique Concerns</u>
                                                                                </p>
                                                                                {this.state.toggleStatus[activity.Id]["concerns"] && 
                                                                                    <span className="cloak conf-macro output-block" style={{}} data-hasbody="true" data-macro-name="cloak">
                                                                                        <div dangerouslySetInnerHTML={{__html: `${activity.Unique_concerns__c || "NP"}`}}></div>
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td className="confluenceTd" style={{textAlign: "left"}}>
                                                                            <div className="content-wrapper">
                                                                                <p>
                                                                                    <a href={this.confluenceURL+`${activity.Tenant_Links__c}&run_Tenant+Name=run`} target="_blank">
                                                                                        <span style={{color: "rgb(0,0,128)"}}>
                                                                                            <strong>{activity.Tenant_Data_Center__c}-{cleanProd(activity.Tenant_Type__c)} - </strong>
                                                                                        </span>
                                                                                        Tenant Details
                                                                                    </a>
                                                                                </p>
                                                                                <p className="cloakToggle" onClick={() => this.handleToggle(activity.Id, "products")} style={{cursor: "pointer"}}>
                                                                                    <span className="cloakToggle conf-macro output-inlne" data-macro-name="toggle-cloak">
                                                                                        <span className={this.state.toggleStatus[activity.Id]["products"] ? "cloakDefaultClose" : "cloakDefaultOpen"}></span>
                                                                                    </span>
                                                                                    <u>Implemented Products</u>
                                                                                </p>
                                                                                {this.state.toggleStatus[activity.Id]["products"] && 
                                                                                    <span className="cloak conf-macro output-block" style={{}} data-hasbody="true" data-macro-name="cloak">
                                                                                        <div dangerouslySetInnerHTML={{__html: `${activity.Implemented_Products__c || "NP"}`}}></div>
                                                                                    </span>
                                                                                }
                                                                                <p className="cloakToggle" onClick={() => this.handleToggle(activity.Id, "volumes")} style={{cursor: "pointer"}}>
                                                                                    <span className="cloakToggle conf-macro output-inline" data-macro-name="toggle-cloak">
                                                                                        <span className={this.state.toggleStatus[activity.Id]["volumes"] ? "cloakDefaultClose" : "cloakDefaultOpen"}></span>
                                                                                    </span>
                                                                                    <u>Key Volumes</u>
                                                                                </p>
                                                                                {this.state.toggleStatus[activity.Id]["volumes"] && 
                                                                                    <span className="cloak conf-macro output-block" style={{}} data-hasbody="true" data-macro-name="cloak">
                                                                                        <div dangerouslySetInnerHTML={{__html: `${activity.Key_Volumes__c || "NP"}`}}></div>
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td className="confluenceTd" style={{textAlign: "left"}}>
                                                                            <div className="content-wrapper">
                                                                                <p>
                                                                                    <span>
                                                                                        <span>
                                                                                            <span style={{color: "rgb(0,0, 128)"}}>
                                                                                                <strong>PRM - </strong>
                                                                                            </span>
                                                                                            {activity.Assigned_PRM_Text__c}
                                                                                        </span>
                                                                                    </span>
                                                                                </p>
                                                                                <p>
                                                                                <span>
                                                                                {activity.Secondary_PRM__c !== null && activity.Secondary_PRM__c !== "" && 
                                                                                    (<span>
                                                                                    <span id="secId" style={{color: "rgb(0,0, 128)"}}>
                                                                                    <strong>Secondary PRM - </strong>
                                                                                    </span>
                                                                                    {activity.Secondary_PRM__c}
                                                                                    </span>)
                                                                                }
                                                                                </span>
                                                                                </p>
                                                                                <p className="auto-cursor-target cloakToggle" onClick={() => this.handleToggle(activity.Id, "actions")}>
                                                                                    <span className="cloakToggle conf-macro output-inlne" data-macro-name="toggle-cloak">
                                                                                        <span className={this.state.toggleStatus[activity.Id]["actions"] ? "cloakDefaultClose" : "cloakDefaultOpen"}></span>
                                                                                    </span>
                                                                                    <u>PRM Actions</u>
                                                                                </p>
                                                                                {this.state.toggleStatus[activity.Id]["actions"] && 
                                                                                    <span className="cloak conf-macro output-block" style={{}} data-hasbody="true" data-macro-name="cloak">
                                                                                        <div dangerouslySetInnerHTML={{__html: `${activity.PRM_Actions__c || "NP"}`}}></div>
                                                                                    </span>
                                                                                }
                                                                                <p className="auto-cursor-target cloakToggle" onClick={() => this.handleToggle(activity.Id, "summary")}>
                                                                                    <span className="cloakToggle conf-macro output-inline" data-macro-name="toggle-cloak">
                                                                                        <span className={this.state.toggleStatus[activity.Id]["summary"] ? "cloakDefaultClose" : "cloakDefaultOpen"}></span>
                                                                                    </span>
                                                                                    <u>Status Summary</u>
                                                                                </p>
                                                                                {this.state.toggleStatus[activity.Id]["summary"] && 
                                                                                    <span className="cloak conf-macro output-block" style={{}} data-hasbody="true" data-macro-name="cloak">
                                                                                        <div dangerouslySetInnerHTML={{__html: `${activity.Activity_Summary__c || "NP"}`}}></div>
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </Fragment>
                                ) : (
                                    false
                                )}
                            </Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Wallboard
